import { MaterialRegistrationUnit } from '../collected-materials/collected-materials.types'
import { MinimalCompanyDto } from '../company'
import { MinimalFileUploadDto } from '../fileupload/file-upload.types'
import { MassBalanceAccount } from '../mass-balance'
import { MaterialType } from '../materialtype/material-type.types'
import { OwnerType } from '../owner'
import { ProductType } from '../producttype/product-type.types'
import { QuantityUnit } from '../quantityunit'
import { MinimalUserDto } from '../user/user.types'

export enum HubEventType {
  REGISTRATION = 'REGISTRATION',
  SEGREGATION = 'SEGREGATION',
  PROCESSING = 'PROCESSING',
  CLEARANCE = 'CLEARANCE',
  CERTIFICATION = 'CERTIFICATION'
}

export interface HubEventDto {
  uuid: string
  type: MassBalanceAccount
  weight: number
  quantityUnit: QuantityUnit
  materialType: MaterialType
  productType: ProductType
  registrationUnit: MaterialRegistrationUnit
  company: MinimalCompanyDto
  pipelineOwner: string
  createdAt: string
  createdBy: MinimalUserDto
  review: HubEventReviewDto | null
  weighedAt: string

  picture: MinimalFileUploadDto
  secondPicture: MinimalFileUploadDto | null
  pictureOwnerType: OwnerType

  licensePlate: string
  transitDestination: string

  certifiedWeight: number
  moisturePercentage: number
  calorificValue: number
  ashPercentage: number

  collectorTrackingNumber: string
  collectorHouseName: string
}

export enum HubEventReviewStatus {
  APPROVED = 'APPROVED',
  AUTO_APPROVED = 'AUTO_APPROVED',
  FLAGGED = 'FLAGGED',
  AUTO_FLAGGED = 'AUTO_FLAGGED'
}

export enum HubEventReviewTag {
  IMAGE_COMPLIANCE = 'IMAGE_COMPLIANCE',
  IMAGE_MATERIAL_VISIBILITY = 'IMAGE_MATERIAL_VISIBILITY',
  IMAGE_BAG_VISIBILITY = 'IMAGE_BAG_VISIBILITY',
  IMAGE_SCALE_VISIBILITY = 'IMAGE_SCALE_VISIBILITY',
  IMAGE_POOR_QUALITY = 'IMAGE_POOR_QUALITY',
  IMAGE_DATA_MISMATCH = 'IMAGE_DATA_MISMATCH',
  IMAGE_WEIGHT_MISMATCH = 'IMAGE_WEIGHT_MISMATCH',
  IMAGE_MATERIAL_QUALITY = 'IMAGE_MATERIAL_QUALITY',
  IMAGE_HOMOGENEOUS_MATERIAL = 'IMAGE_HOMOGENEOUS_MATERIAL',
  IMAGE_LICENSE_PLATE_MISMATCH = 'IMAGE_LICENSE_PLATE_MISMATCH',
  IMAGE_TEXT_READABILITY = 'IMAGE_TEXT_READABILITY',
  IMAGE_CHILD_LABOUR = 'IMAGE_CHILD_LABOUR',
  LOCATION_MISMATCH = 'LOCATION_MISMATCH',
  SUPPLIER_TRANSPORT_WEIGHT_OUTLIER = 'SUPPLIER_TRANSPORT_WEIGHT_OUTLIER',
  MATERIAL_ANOMALY = 'MATERIAL_ANOMALY',
  DATA_LOCATION = 'DATA_LOCATION',
  DATA_OUTLIER = 'DATA_OUTLIER',
  MOISTURE_PERCENTAGE_OUTLIER = 'MOISTURE_PERCENTAGE_OUTLIER',
  WEIGHT_OUTLIER = 'WEIGHT_OUTLIER',
  DATA_UNITS = 'DATA_UNITS',
  DUPLICATE = 'DUPLICATE',
  DOCUMENT_CERTIFICATE_WEIGHT_MISMATCH = 'DOCUMENT_CERTIFICATE_WEIGHT_MISMATCH',
  DOCUMENT_WEIGHSLIP_WEIGHT_MISMATCH = 'DOCUMENT_WEIGHSLIP_WEIGHT_MISMATCH',
  DOCUMENT_MOISTURE_PERCENTAGE_MISMATCH = 'DOCUMENT_MOISTURE_PERCENTAGE_MISMATCH',
  IMAGE_DUPLICATE = 'IMAGE_DUPLICATE',
  OTHER = 'OTHER',
  HUB_EVENT_AUTO_CREATED = 'HUB_EVENT_AUTO_CREATED',
  OCR_DATA_NOT_DETECTED = 'OCR_DATA_NOT_DETECTED'
}

export interface HubEventReviewDto {
  status: HubEventReviewStatus
  tags: HubEventReviewTag[]
  comment: string | null
}

export interface HubEventReviewTagOption {
  label: HubEventReviewTag
  value: HubEventReviewTag
}

export const getSupportedHubEventReviewTags = (): HubEventReviewTagOption[] => {
  return Object.freeze([
    HubEventReviewTag.IMAGE_COMPLIANCE,
    HubEventReviewTag.IMAGE_SCALE_VISIBILITY,
    HubEventReviewTag.IMAGE_BAG_VISIBILITY,
    HubEventReviewTag.IMAGE_WEIGHT_MISMATCH,
    HubEventReviewTag.IMAGE_TEXT_READABILITY,
    HubEventReviewTag.IMAGE_CHILD_LABOUR,
    HubEventReviewTag.WEIGHT_OUTLIER,
    HubEventReviewTag.DUPLICATE,
    HubEventReviewTag.IMAGE_DUPLICATE,
    HubEventReviewTag.IMAGE_LICENSE_PLATE_MISMATCH,
    HubEventReviewTag.IMAGE_POOR_QUALITY,
    HubEventReviewTag.MOISTURE_PERCENTAGE_OUTLIER,
    HubEventReviewTag.SUPPLIER_TRANSPORT_WEIGHT_OUTLIER,
    HubEventReviewTag.MATERIAL_ANOMALY,
    HubEventReviewTag.LOCATION_MISMATCH,
    HubEventReviewTag.DOCUMENT_CERTIFICATE_WEIGHT_MISMATCH,
    HubEventReviewTag.DOCUMENT_WEIGHSLIP_WEIGHT_MISMATCH,
    HubEventReviewTag.DOCUMENT_MOISTURE_PERCENTAGE_MISMATCH,
    HubEventReviewTag.HUB_EVENT_AUTO_CREATED,
    HubEventReviewTag.OCR_DATA_NOT_DETECTED
  ]).map((reviewTag) => ({
    label: reviewTag,
    value: HubEventReviewTag[reviewTag]
  }))
}
